export const FEATURES_OPTIONS = [
  { id: "Traceability", name: "Traceability" },
  { id: "Metrics", name: "Metrics" },
  { id: "Eco-Score", name: "Eco-Score" },
  { id: "Library", name: "Library" },
  { id: "Digitization", name: "Digitization" },
  { id: "Ecodesign", name: "Ecodesign" },
];

export const NAMES = [
  { key: 1, name: "Retail", source: "primaryPackaging" },
  { key: 2, name: "Warehouse", source: "secondaryPackaging" },
  { key: 3, name: "Shipping", source: "tertiaryPackaging" },
];

export const REFERENCE_TYPES = [
  { id: "ARTICLE", name: "Article" },
  { id: "MATERIAL", name: "Material" },
  { id: "SECOND_HAND", name: "Second hand" },
];

export const FUNCTIONAL_UNIT = [
  {id: '1 unit', name: '1 unit'},
  {id: '1 kg', name: '1 kg'},
  {id: '1 m', name: '1 m'},
  {id: '1 m2', name: '1 m²'},
  {id: '1 spool', name: '1 spool'},

]

export const _STATUS = [
  { id: "Collecting data", name: "Collecting data" },
  { id: "Validating data", name: "Validating data" },
  { id: "Completed", name: "Completed" },
];

export const scoreChoices = [
  { id: 5, name: "5" },
  { id: 10, name: "10" },
  { id: 15, name: "15" },
];

export const TIER_OPTIONS = [
  { id: "Not applicable", name: "Not applicable" },
  { id: "Tier 0", name: "Tier 0" },
  { id: "Tier 1", name: "Tier 1" },
  { id: "Tier 2", name: "Tier 2" },
  { id: "Tier 3", name: "Tier 3" },
  { id: "Tier 4", name: "Tier 4" },
];

export const categoryChoices = [
  { id: "Ship", name: "Ship" },
  { id: "Plane", name: "Plane" },
  { id: "Train", name: "Train" },
];

export const distanceChoices = [
  { id: "infinite", name: "Infinite" },
  { id: "800 or less", name: "800 or less" },
  { id: "800 - 1500", name: "800 - 1500" },
  { id: "1500 - 4000", name: "1500 - 4000" },
  { id: "4000 or more", name: "4000 or more" },
];

export const tierChoices = [
  { id: "Tier 1", name: "Tier 1" },
  { id: "Tier 2", name: "Tier 2" },
  { id: "Tier 3", name: "Tier 3" },
  { id: "Tier 4", name: "Tier 4" },
];

export const FUNCTIONAL_UNITS = [
  { id: "KG", name: "Kg" },
  { id: "UNIT", name: "Unit" },
];

export const DEFAULT_TYPES = [
  { id: "Custom", name: "Custom" },
  { id: "Generic", name: "Generic" },
  { id: "Multi-process", name: "Multi-process" },
];

export const PACKAGING_CATEGORY = [
  { id: "Bio-based plastic", name: "Bio-based plastic" },
  { id: "Metal", name: "Metal" },
  { id: "Natural textile", name: "Natural textile" },
  { id: "Paper/cardboard", name: "Paper/cardboard" },
  { id: "Plastic", name: "Plastic" },
  { id: "Recycled paper/cardboard", name: "Recycled paper/cardboard" },
  { id: "Recycled plastic", name: "Recycled plastic" },
  { id: "Recycled textile", name: "Recycled textile" },
  { id: "Synthetic textile", name: "Synthetic textile" },
  { id: "Wood", name: "Wood" },
]

export const ARTICLE_CATEGORY_TYPE = [
  { id: "Single", name: "Single" },
  { id: "Pair", name: "Pair" },
  { id: "Set", name: "Set" },
]

export const RECYCLABILITY_FRANCE = [
  { id: "Not recyclable", name: "Not recyclable" },
  { id: "Mostly recyclable", name: "Mostly recyclable" },
  { id: "Entirely recyclable", name: "Entirely recyclable" },
  { id: "Recyclable in a packaging of the same nature", name: "Recyclable in a packaging of the same nature" },
]

export const numberFiedDefaultOptions = {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}