import { hostname } from "./api";
import jwt_decode from "jwt-decode";

const authProvider = (tokenOps) => ({
  login: ({ username, password }) => {
    const request = new Request(hostname + "/auth/access", {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        tokenOps.setRootToken(auth);
        const d = jwt_decode(auth.accessToken);
        if (!d.roles.includes("ADMIN")) {
          throw new Error("Wrong roles")
        }
      })
      .catch(e => {
        throw e;
      });
  },
  getIdentity: () => {
    return { id: tokenOps.account?.data?.userId };
  },
  getPermissions: () => {
    const roles = tokenOps.root?.data?.roles;
    if (!roles) {
      return Promise.reject();
    }
    return Promise.resolve({
      roles: roles,
    });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      tokenOps.removeRootToken();
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => tokenOps.account?.accessToken ? Promise.resolve() : Promise.reject(),
  logout: () => {
    tokenOps.removeRootToken();
    return Promise.resolve();
  },
});

export default authProvider;
